enum FeatureFlags {
  COMPONENT_TESTING = 'COMPONENT_TESTING',
  CUSTOM_TIMEOUT = 'CUSTOM_TIMEOUT',
  CUSTOMER_MATCH_SHP_ENABLED = 'CUSTOMER_MATCH_SHP_ENABLED',
  FAKE_CIN = 'FAKE_CIN',
  FAKE_NTB_PROFILE = 'FAKE_NTB_PROFILE',
  FVQ_SHP_ENABLED = 'FVQ_SHP_ENABLED',
  NOV_ENABLED = 'NOV_ENABLED',
  SHOW_API_ERROR = 'SHOW_API_ERROR',
}

export default FeatureFlags
